import { app } from "../firebase/firebase.js";
import { applyActionCode, getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import "../style/emailConfirmation.css";
import EmailIcon from "../assets/email-icon.jsx";

const redirect = (url) => {
   window.location.replace(url);
};

function EmailConfirmation({ oobCode, continueUrl }) {
   const { t } = useTranslation();
   const auth = getAuth(app);

   applyActionCode(auth, oobCode)
      .then((res) => {
         console.log(res);
      })
      .catch((err) => {
         console.log(err);
      });

   return (
      <div className="container">
         <span>
            <EmailIcon />
         </span>
         <h3 className="green-title">{t("email.confirmation.title")}</h3>
         <p className="green-text">{t("email.confirmation.text")}</p>
         {continueUrl ? redirect(continueUrl) : ""}
      </div>
   );
}

export default EmailConfirmation;
