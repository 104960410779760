const checkPasswordLength = (password) => {
  return password.length >= 6;
};

const checkPasswordContainsUppercase = (password) => {
  return /[A-Z]/.test(password);
};

const checkPasswordContainsLowercase = (password) => {
  return /[a-z]/.test(password);
};

const checkPasswordHasNumber = (password) => {
  return /[0-9]/.test(password);
};

const passwordAndConfirmationEqual = (password, confirmation) => {
  return password === confirmation;
};

module.exports = {
  checkPasswordLength,
  checkPasswordContainsUppercase,
  checkPasswordContainsLowercase,
  checkPasswordHasNumber,
  passwordAndConfirmationEqual,
};
