const CheckTrue = () => {
   return (
      <svg
         width="15"
         height="15"
         viewBox="0 0 15 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M7.5 13.75C6.63542 13.75 5.82292 13.5858 5.0625 13.2575C4.30208 12.9296 3.64062 12.4844 3.07813 11.9219C2.51563 11.3594 2.07042 10.6979 1.7425 9.9375C1.41417 9.17708 1.25 8.36458 1.25 7.5C1.25 6.63542 1.41417 5.82292 1.7425 5.0625C2.07042 4.30208 2.51563 3.64062 3.07813 3.07813C3.64062 2.51563 4.30208 2.07021 5.0625 1.74187C5.82292 1.41396 6.63542 1.25 7.5 1.25C8.36458 1.25 9.17708 1.41396 9.9375 1.74187C10.6979 2.07021 11.3594 2.51563 11.9219 3.07813C12.4844 3.64062 12.9296 4.30208 13.2575 5.0625C13.5858 5.82292 13.75 6.63542 13.75 7.5C13.75 8.36458 13.5858 9.17708 13.2575 9.9375C12.9296 10.6979 12.4844 11.3594 11.9219 11.9219C11.3594 12.4844 10.6979 12.9296 9.9375 13.2575C9.17708 13.5858 8.36458 13.75 7.5 13.75Z"
            fill="#40F1A8"
         />
         <path
            d="M6.41207 8.61918L6.76563 8.97273L7.11918 8.61918L10.2129 5.52543C10.2246 5.51378 10.2389 5.5 10.2969 5.5C10.3549 5.5 10.3692 5.51378 10.3808 5.52543C10.4184 5.56297 10.4219 5.58398 10.4219 5.60938C10.4219 5.64767 10.4143 5.65983 10.3964 5.6777L6.84957 9.22457C6.83792 9.23622 6.82365 9.25 6.76563 9.25C6.7076 9.25 6.69333 9.23622 6.68168 9.22457L4.90043 7.44332L4.54688 7.79688L4.90043 7.44332C4.88878 7.43167 4.875 7.4174 4.875 7.35938C4.875 7.32269 4.88051 7.30349 4.88751 7.29139C4.932 7.26068 4.95835 7.25355 4.96639 7.25179C4.9751 7.24988 4.98022 7.25016 4.98854 7.25227C5.00054 7.25531 5.01857 7.26238 5.04969 7.28001C5.06188 7.28692 5.06891 7.29115 5.07864 7.297C5.08558 7.30117 5.09391 7.30618 5.10648 7.31359L6.41207 8.61918Z"
            fill="#40F1A8"
            stroke="white"
         />
      </svg>
   );
};

export default CheckTrue;
