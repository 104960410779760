const EmailIcon = () => {
   return (
      <svg
         width="127"
         height="81"
         viewBox="0 0 127 81"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M113.5 13.5H59.5C55.7875 13.5 52.7837 16.5375 52.7837 20.25L52.75 60.75C52.75 64.4625 55.7875 67.5 59.5 67.5H113.5C117.213 67.5 120.25 64.4625 120.25 60.75V20.25C120.25 16.5375 117.213 13.5 113.5 13.5ZM113.5 60.75H59.5V27L86.5 43.875L113.5 27V60.75ZM86.5 37.125L59.5 20.25H113.5L86.5 37.125Z"
            fill="#40F1A8"
         />
         <line
            x1="39.5"
            y1="48.5"
            x2="2.5"
            y2="48.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
         <line
            x1="39.5"
            y1="36.5"
            x2="18.5"
            y2="36.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
         <line
            x1="39.5"
            y1="24.5"
            x2="31.5"
            y2="24.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
      </svg>
   );
};

export default EmailIcon;
