const EyeOpen = () => {
   return (
      <svg
         width="22"
         height="22"
         viewBox="0 0 22 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M22 11C22 11 17.875 3.4375 11 3.4375C4.125 3.4375 0 11 0 11C0 11 4.125 18.5625 11 18.5625C17.875 18.5625 22 11 22 11ZM1.61287 11C2.27771 9.98954 3.04238 9.04844 3.89538 8.19087C5.665 6.4185 8.085 4.8125 11 4.8125C13.915 4.8125 16.3336 6.4185 18.106 8.19087C18.959 9.04844 19.7237 9.98954 20.3885 11C20.3088 11.1196 20.2208 11.2516 20.1204 11.396C19.6597 12.056 18.9791 12.936 18.106 13.8091C16.3336 15.5815 13.9136 17.1875 11 17.1875C8.085 17.1875 5.66638 15.5815 3.894 13.8091C3.04101 12.9516 2.27634 12.0104 1.6115 11H1.61287Z"
            fill="#0B3838"
         />
         <path
            d="M11 7.5625C10.0883 7.5625 9.21398 7.92466 8.56932 8.56932C7.92466 9.21398 7.5625 10.0883 7.5625 11C7.5625 11.9117 7.92466 12.786 8.56932 13.4307C9.21398 14.0753 10.0883 14.4375 11 14.4375C11.9117 14.4375 12.786 14.0753 13.4307 13.4307C14.0753 12.786 14.4375 11.9117 14.4375 11C14.4375 10.0883 14.0753 9.21398 13.4307 8.56932C12.786 7.92466 11.9117 7.5625 11 7.5625ZM6.1875 11C6.1875 9.72365 6.69453 8.49957 7.59705 7.59705C8.49957 6.69453 9.72365 6.1875 11 6.1875C12.2764 6.1875 13.5004 6.69453 14.403 7.59705C15.3055 8.49957 15.8125 9.72365 15.8125 11C15.8125 12.2764 15.3055 13.5004 14.403 14.403C13.5004 15.3055 12.2764 15.8125 11 15.8125C9.72365 15.8125 8.49957 15.3055 7.59705 14.403C6.69453 13.5004 6.1875 12.2764 6.1875 11Z"
            fill="#0B3838"
         />
      </svg>
   );
};

export default EyeOpen;
