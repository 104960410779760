const LockIcon = () => {
   return (
      <svg
         width="103"
         height="64"
         viewBox="0 0 103 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M86.9993 23.1118H84.3327V18.0324C84.3327 11.0229 78.3593 5.33398 70.9993 5.33398C63.6393 5.33398 57.666 11.0229 57.666 18.0324V23.1118H54.9993C52.066 23.1118 49.666 25.3975 49.666 28.1911V53.588C49.666 56.3816 52.066 58.6673 54.9993 58.6673H86.9993C89.9327 58.6673 92.3327 56.3816 92.3327 53.588V28.1911C92.3327 25.3975 89.9327 23.1118 86.9993 23.1118ZM62.9993 18.0324C62.9993 13.8165 66.5727 10.4133 70.9993 10.4133C75.426 10.4133 78.9993 13.8165 78.9993 18.0324V23.1118H62.9993V18.0324ZM86.9993 53.588H54.9993V28.1911H86.9993V53.588ZM70.9993 45.9689C73.9327 45.9689 76.3327 43.6832 76.3327 40.8895C76.3327 38.0959 73.9327 35.8102 70.9993 35.8102C68.066 35.8102 65.666 38.0959 65.666 40.8895C65.666 43.6832 68.066 45.9689 70.9993 45.9689Z"
            fill="#40F1A8"
         />
         <line
            x1="39.5"
            y1="46.5"
            x2="2.5"
            y2="46.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
         <line
            x1="39.5"
            y1="34.5"
            x2="18.5"
            y2="34.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
         <line
            x1="39.5"
            y1="22.5"
            x2="31.5"
            y2="22.5"
            stroke="#40F1A8"
            strokeWidth="5"
            strokeLinecap="round"
         />
      </svg>
   );
};

export default LockIcon;
